/* eslint-disable mosaic-js/no-useless-div */
import { ReactNode, useState } from "react"
import Typography from "compass/data/Typography"
import Spinner from "compass-local/Spinner"
import LinkButtonDONOTUSE from "compass-local/legacy/LinkButtonDONOTUSE"
import Alert from "compass-local/Alert"
import { t } from "content"
import { MSArray } from "msutils"
import { unreachable } from "msutils/misc"
import { LayoutSwitcher } from "utils/components"
import { Action } from "utils/actions"
import { LayoutFlagContextProvider } from "compass/layout/LayoutFlagContext"
import { DrawerBody as BaseDrawerBody } from "compass/v2/DrawerBody"
import { BaseLayout } from "compass/baseLayout"
import { useDrawerContext } from "compass/v2/Drawer"
import WarningOverlay from "compass-local/WarningOverlay"
import Icon from "compass/data/Icon"
import { theme2 } from "theme2"
import { useModalContext } from "compass/v2/Modal"
import { useKeyListener } from "compass/_internal/utils"
import useScreenSize from "compass/theme/useScreenSize"
import { useBodySize } from "compass/dom-utils"
import { ModalBody } from "compass/v2/ModalBody"
import { useEscapeListener } from "compass/v2/EscapeContext"
import { FormSize, useFormContext } from "./openerContext"

type FormBodyProps = {
  title?: string
  back?: () => void
  error?: Error | string | null
  pageName?: ReactNode
  pageNumber?: `${number} / ${number}`
  summary?: ReactNode
  footer?: ReactNode
  actions?: Action.Config[]
  warnOnClose?: boolean
  // TODO: not supported for drawer
  autosave?: {
    isLoading: boolean
    isSaved: boolean
  }
  size?: FormSize
  children: ReactNode
}

function DrawerBody({
  title,
  back,
  error,
  pageName,
  pageNumber,
  warnOnClose,
  summary,
  actions,
  footer,
  size,
  children,
}: FormBodyProps) {
  const { shouldCloseRef, forceClose } = useDrawerContext()
  const [warningActive, setWarningActive] = useState(false)

  shouldCloseRef.current = () => {
    if (warnOnClose) {
      setWarningActive(true)
      return false
    }
    return true
  }

  return (
    <BaseDrawerBody
      title={title}
      size={size}
      back={back}
      footer={
        <LayoutSwitcher
          sm={
            (MSArray.isNonEmpty(actions ?? []) || summary || footer) && (
              <BaseLayout.VStack gap={3}>
                {footer}
                {summary}
                {MSArray.isNonEmpty(actions ?? []) && (
                  <Action.UIConfigProvider value={{ theme: "primary" }}>
                    {[...(actions ?? [])].reverse().map((x, i) => (
                      <Action.Mount key={`${x.name}-${i}`} {...x} />
                    ))}
                  </Action.UIConfigProvider>
                )}
              </BaseLayout.VStack>
            )
          }
          lg={
            (MSArray.isNonEmpty(actions ?? []) || summary || footer) && (
              <BaseLayout.VStack gap={3}>
                {footer}
                {summary}
                {MSArray.isNonEmpty(actions ?? []) && (
                  <BaseLayout.HStack justify="end" view={{}}>
                    <Action.UIConfigProvider value={{ theme: "primary" }}>
                      <BaseLayout.HStack gap={4} view={{ collapseWidth: true }}>
                        {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
                      </BaseLayout.HStack>
                    </Action.UIConfigProvider>
                  </BaseLayout.HStack>
                )}
              </BaseLayout.VStack>
            )
          }
        />
      }
    >
      {(pageName || pageNumber) && (
        <BaseLayout.HStack gap={2} align="baseline">
          {pageNumber && (
            <Typography variant="bodybold" className="text-th-coolgrey-1">
              {pageNumber}
            </Typography>
          )}
          <Typography variant="title" className="text-th-grey-2">
            {pageName}
          </Typography>
        </BaseLayout.HStack>
      )}
      {error && !(error instanceof Error && !error.message) && (
        <Alert>{error instanceof Error ? error.message : error}</Alert>
      )}
      <BaseLayout.VStack gap={5} view={{ overridesDONOTUSE: { flexGrow: 1 } }}>
        {children}
      </BaseLayout.VStack>
      {warningActive && (
        <WarningOverlay
          closeForm={() => {
            forceClose()
            setWarningActive(false)
          }}
          dismissWarning={() => setWarningActive(false)}
        />
      )}
    </BaseDrawerBody>
  )
}

function FullScreenModalBody({
  title,
  pageNumber,
  pageName,
  error,
  back,
  autosave,
  summary,
  actions,
  footer,
  warnOnClose,
  children,
}: FormBodyProps) {
  const { shouldCloseRef, forceClose, close } = useModalContext()
  const [warningActive, setWarningActive] = useState(false)
  const sz = useScreenSize()

  shouldCloseRef.current = () => {
    if (warnOnClose) {
      setWarningActive(true)
      return false
    }
    return true
  }

  useEscapeListener(() => {
    if (sz === "sm") return
    close()
  })

  const bodySize = useBodySize()

  return (
    <div className="vflex isolate bg-[white]" style={bodySize}>
      <div className="flex justify-between px-8 py-5 gap-10 border-b border-th-warmgrey-2 items-center">
        <div className="flex gap-5 items-center">
          <Typography variant="title">{title}</Typography>
          <div className="bg-th-warmgrey-2 w-px h-4" />
          <div className="flex gap-2 items-baseline">
            {pageNumber && (
              <Typography variant="bodybold" className="text-th-coolgrey-1">
                {pageNumber}
              </Typography>
            )}
            <Typography variant="bodybold">{pageName}</Typography>
          </div>
        </div>
        <div className="flex gap-5 items-center">
          {(autosave?.isSaved || autosave?.isLoading) && (
            <Typography variant="label" className="text-th-coolgrey-1">
              {autosave.isLoading ? <Spinner /> : t("Draft auto-saved")}
            </Typography>
          )}
          <Icon
            name="x"
            height={12}
            thickness={2.4}
            onClick={close}
            cursor="pointer"
            rawColor={theme2.colors["coolgrey-1"]}
          />
        </div>
      </div>
      {error && !(error instanceof Error && !error.message) && (
        <div className="p-3">
          <Alert>{error instanceof Error ? error.message : error}</Alert>
        </div>
      )}
      {children}
      <div className="vflex gap-2 px-8 py-3 border-t border-th-warmgrey-2">
        {summary}
        {footer}
        <div className="flex justify-between gap-8 items-center">
          {back ? (
            <LinkButtonDONOTUSE
              variant="dark"
              icon={<Icon name={["arrow", "-90"]} rawColor={theme2.colors["brown-2"]} />}
              onClick={back}
            >
              {t("Back")}
            </LinkButtonDONOTUSE>
          ) : (
            <div />
          )}
          <div className="flex gap-4 items-center">
            <Action.UIConfigProvider value={{ theme: "primary" }}>
              {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </Action.UIConfigProvider>
          </div>
        </div>
      </div>
      {warningActive && (
        <WarningOverlay
          closeForm={() => {
            forceClose()
            setWarningActive(false)
          }}
          dismissWarning={() => setWarningActive(false)}
        />
      )}
    </div>
  )
}

function SmallModalBody({
  title,
  summary,
  actions,
  error,
  size,
  footer,
  warnOnClose,
  children,
}: FormBodyProps) {
  const { shouldCloseRef, forceClose, close } = useModalContext()
  const [warningActive, setWarningActive] = useState(false)
  const sz = useScreenSize()

  shouldCloseRef.current = () => {
    if (warnOnClose) {
      setWarningActive(true)
      return false
    }
    return true
  }

  useKeyListener("Escape", (e) => {
    if (sz === "sm") return

    close()
    e.stopPropagation()
  })

  return (
    <ModalBody
      title={title}
      size={size === "medium" ? "large" : "small"}
      footer={
        (MSArray.isNonEmpty(actions ?? []) || summary || footer) && (
          <LayoutSwitcher
            sm={
              <BaseLayout.VStack gap={3}>
                {footer}
                {summary}
                {MSArray.isNonEmpty(actions ?? []) && (
                  <Action.UIConfigProvider value={{ theme: "primary" }}>
                    {[...(actions ?? [])].reverse().map((x, i) => (
                      <Action.Mount key={`${x.name}-${i}`} {...x} />
                    ))}
                  </Action.UIConfigProvider>
                )}
              </BaseLayout.VStack>
            }
            lg={
              <BaseLayout.VStack gap={3}>
                {footer}
                {summary}
                {MSArray.isNonEmpty(actions ?? []) && (
                  <BaseLayout.HStack justify="end" view={{}}>
                    <Action.UIConfigProvider value={{ theme: "primary" }}>
                      <BaseLayout.HStack gap={4} view={{ collapseWidth: true }}>
                        {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
                      </BaseLayout.HStack>
                    </Action.UIConfigProvider>
                  </BaseLayout.HStack>
                )}
              </BaseLayout.VStack>
            }
          />
        )
      }
    >
      {error && !(error instanceof Error && !error.message) && (
        <Alert>{error instanceof Error ? error.message : error}</Alert>
      )}
      <BaseLayout.VStack gap={5} view={{ overridesDONOTUSE: { flexGrow: 1 } }}>
        {children}
      </BaseLayout.VStack>
      {warningActive && (
        <WarningOverlay
          closeForm={() => {
            forceClose()
            setWarningActive(false)
          }}
          dismissWarning={() => setWarningActive(false)}
        />
      )}
    </ModalBody>
  )
}

export function FormBody(props: FormBodyProps) {
  const { ui } = useFormContext()
  switch (ui) {
    case "modal":
      if (!props.size || props.size === "large") {
        return <FullScreenModalBody {...props} />
      } else {
        return <SmallModalBody {...props} />
      }
    case "drawer":
      return (
        <LayoutFlagContextProvider disabledTableInset>
          <DrawerBody {...props} />
        </LayoutFlagContextProvider>
      )
    default:
      return unreachable(ui)
  }
}
