import { FC, ReactNode } from "react"
import useScreenSize from "compass/theme/useScreenSize"

export type SubcomponentTypes<T extends Record<string, any>> = {
  [K in keyof T]: FC<T[K]>
}

type LayoutSwitcherProps = { sm: ReactNode; lg: ReactNode }
export const LayoutSwitcher = function Ls({ sm, lg }: LayoutSwitcherProps) {
  const sz = useScreenSize()
  // eslint-disable-next-line
  return <>{sz === "sm" ? sm : lg}</>
}
