import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { unreachable } from "msutils/misc"
import { BaseLayout } from "../baseLayout"
import Icon from "../data/Icon"
import Typography from "../data/Typography"
import { useTheme, VariantsOf } from "../theme"
import Divider from "../data/Divider"
import { useKeyListener } from "../_internal/utils"
import useScreenSize from "../theme/useScreenSize"
import { useDrawerContext } from "./Drawer"

declare module "../theme" {
  interface ComponentTheme {
    drawerBody: {
      headerBackground: string
      closeIconColor: string
      background: string
      borderRadius: number
      shadowColor: string
      padding: number | [number, number]
    }
  }
}

const sizes = { small: 440, medium: 600, large: 1680 }
const Ctx = createContext<{ setInnerWidth: (s: number | null) => void } | undefined>(undefined)

// TODO: it's weird that this is a compass-level component - this should just be implemented at the application layer

export function DrawerBody({
  title,
  size = "small",
  variant,
  back,
  footer,
  children,
}: {
  title?: string
  size?: keyof typeof sizes
  variant?: VariantsOf<"drawerBody">
  back?: () => void
  footer?: ReactNode
  children: ReactNode
}) {
  const sz = useScreenSize()
  const theme = useTheme("drawerBody", variant)
  const { close, side } = useDrawerContext()

  const width = side === "right" ? Math.min(window.innerWidth - 20, sizes[size]) : undefined
  const height = side === "bottom" ? `${document.body.clientHeight}px` : undefined

  const [innerWidth, setInnerWidth] = useState<number | null>(null)
  const ctxValue = useMemo(() => ({ setInnerWidth }), [])
  const parentBodyCtx = useContext(Ctx)
  const apparentWidth = innerWidth ? innerWidth + 20 : width

  useEffect(() => {
    if (apparentWidth) {
      parentBodyCtx?.setInnerWidth(apparentWidth)
      return () => {
        parentBodyCtx?.setInnerWidth(null)
      }
    } else {
      return () => {}
    }
  }, [apparentWidth, parentBodyCtx])

  useKeyListener("Escape", (e) => {
    // this is a hacky way to see if there is no child drawer open...
    // (which actually doesn't work on mobile, since we don't set innerWidths)
    // but mobile doesn't have an Escape key, so I'll just disable it on mobile
    if (sz === "sm") return

    if (!innerWidth) {
      close()
      e.stopPropagation()
    }
  })

  return (
    <BaseLayout.VStack
      view={{
        fixedWidth: width,
        background: theme.background,
        overridesDONOTUSE: {
          ...(side === "right"
            ? {
                borderTopLeftRadius: theme.borderRadius,
                borderBottomLeftRadius: theme.borderRadius,
                boxShadow: `0 -6px 10px 4px ${theme.shadowColor}`,
              }
            : side === "bottom"
            ? {
                borderTopLeftRadius: theme.borderRadius,
                borderTopRightRadius: theme.borderRadius,
                boxShadow: `-2px 0 8px 4px ${theme.shadowColor}`,
              }
            : unreachable(side)),
          height,
          right: innerWidth && width ? innerWidth - width + 20 : 0,
          position: "relative",
          transition: "ease right",
          transitionDuration: "50ms",
          overflowY: "auto",
        },
      }}
    >
      <BaseLayout.HStack
        justify="between"
        align="center"
        view={{
          inset: theme.padding,
          background: theme.headerBackground,
          // TODO: figure out how to not need to specify a zIndex here. Maybe zIndex on sticky is required?
          overridesDONOTUSE: {
            position: "sticky",
            top: 0,
            zIndex: 1,
            borderRadius: theme.borderRadius,
          },
        }}
      >
        <BaseLayout.HStack gap={3} align="center">
          {back && (
            <Icon
              name={["arrow", "-90"]}
              height={18}
              onClick={back}
              rawColor={theme.closeIconColor}
              cursor="pointer"
            />
          )}
          <Typography variant="drawerheader">{title}</Typography>
        </BaseLayout.HStack>
        <Icon
          name="x"
          rawColor={theme.closeIconColor}
          height={16}
          onClick={close}
          cursor="pointer"
        />
      </BaseLayout.HStack>
      <Ctx.Provider value={ctxValue}>
        <BaseLayout.VStack
          gap={5}
          view={{ inset: [0, 8], overridesDONOTUSE: { paddingBottom: 12, flexGrow: 1 } }}
        >
          {children}
        </BaseLayout.VStack>
      </Ctx.Provider>
      {footer && (
        <BaseLayout.View
          background={theme.headerBackground}
          overridesDONOTUSE={{ position: "sticky", bottom: 0, borderRadius: theme.borderRadius }}
        >
          <BaseLayout.View inset={[0, 5]}>
            <Divider />
          </BaseLayout.View>
          <BaseLayout.View inset={5}>{footer}</BaseLayout.View>
        </BaseLayout.View>
      )}
    </BaseLayout.VStack>
  )
}
